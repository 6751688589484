var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"singIn-section"},[_c('div',{staticClass:"uk-container"},[_c('div',{staticClass:"form-border"},[_c('div',{staticClass:"uk-text-center uk-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("form.createNewUser")))])]),_c('v-form',{ref:"form",staticClass:"uk-flex-middle",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.firstName")))]),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || _vm.$t('required.firstName'); }],"placeholder":_vm.$t('form.firstName'),"autocomplete":"off","autocorrect":"off"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.lastName")))]),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || _vm.$t('required.lastName'); }],"placeholder":_vm.$t('form.lastName'),"autocomplete":"off","autocorrect":"off"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.email")))]),_c('v-text-field',{attrs:{"rules":[
              function (v) { return !!v || _vm.$t('required.email'); },
              function (v) { return /.+@.+/.test(v) || _vm.$t('required.validEmail'); }
            ],"placeholder":_vm.$t('form.email'),"autocomplete":"off","autocorrect":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.IDNumber")))]),_c('v-text-field',{attrs:{"type":"number","rules":[function (v) { return !!v || _vm.$t('required.IDNumber'); }],"placeholder":_vm.$t('form.IDNumber'),"autocomplete":"off","autocorrect":"off"},model:{value:(_vm.identityNumber),callback:function ($$v) {_vm.identityNumber=$$v},expression:"identityNumber"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.phoneNumber")))]),_c('v-text-field',{attrs:{"type":"number","rules":[function (v) { return !!v || _vm.$t('required.phoneNumber'); }],"placeholder":_vm.$t('form.phoneNumber'),"autocomplete":"off","autocorrect":"off"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.country")))]),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || _vm.$t('required.country'); }],"placeholder":_vm.$t('form.country'),"autocomplete":"off","autocorrect":"off"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.password")))]),_c('v-text-field',{attrs:{"rules":[
              function (v) { return !!v || _vm.$t('required.password'); },
              function (v) { return v.length >= 10 || _vm.$t('required.validPassword'); }
            ],"placeholder":_vm.$t('form.password'),"autocomplete":"off","autocorrect":"off","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"uk-width-1-3@m ma-0"},[_c('label',[_vm._v(_vm._s(_vm.$t("form.confirmPassword")))]),_c('v-text-field',{attrs:{"rules":[
              function (v) { return !!v || _vm.$t('required.field'); },
              function (v) { return v === _vm.password || _vm.$t('required.notIdentecalPassword'); }
            ],"placeholder":_vm.$t('form.confirmPassword'),"autocomplete":"off","autocorrect":"off","type":"password"},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})],1),_c('div',{staticClass:"uk-width-1-3@m uk-drop-date ma-0"},[_c('label',{staticClass:"uk-form-label"},[_vm._v(_vm._s(_vm.$t("form.birthDate")))]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || _vm.$t('required.birthDate'); }],"readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"#69d1b6"},model:{value:(_vm.birthDay),callback:function ($$v) {_vm.birthDay=$$v},expression:"birthDay"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"#69d1b6","dark":""},on:{"click":function($event){return _vm.$refs.menu.save(_vm.birthDay)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"uk-width-2-5@m mt-2 text-center"},[_c('base-button',{attrs:{"options":{
              block: true,
              isLoading: _vm.loading,
              color: this.primaryColor
            }},on:{"click":function($event){return _vm.signUp()}}},[_vm._v(_vm._s(_vm.$t("form.signup"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("form.bySigning")))])],1),_c('div',{staticClass:"uk-width-1-5@m my-5 m-lg-0 text-center"},[_vm._v(" - "+_vm._s(_vm.$t("or"))+" - ")]),_c('div',{staticClass:"uk-width-2-5@m ma-0 text-center"},[_c('base-button',{attrs:{"options":{ block: true, color: this.primaryColor }}},[_vm._v("Google ")]),_c('p',[_vm._v(_vm._s(_vm.$t("form.signUpThroughGoogle")))])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }