<template>
  <div class="singIn-section">
    <div class="uk-container">
      <div class="form-border">
        <div class="uk-text-center uk-title">
          <h2>{{ $t("form.createNewUser") }}</h2>
        </div>
        <v-form ref="form" class="uk-flex-middle" uk-grid>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.firstName") }}</label>
            <v-text-field
              v-model="firstName"
              :rules="[(v) => !!v || $t('required.firstName')]"
              :placeholder="$t('form.firstName')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.lastName") }}</label>
            <v-text-field
              v-model="lastName"
              :rules="[(v) => !!v || $t('required.lastName')]"
              :placeholder="$t('form.lastName')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.email") }}</label>
            <v-text-field
              v-model="email"
              :rules="[
                (v) => !!v || $t('required.email'),
                (v) => /.+@.+/.test(v) || $t('required.validEmail')
              ]"
              :placeholder="$t('form.email')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.IDNumber") }}</label>
            <v-text-field
              type="number"
              v-model="identityNumber"
              :rules="[(v) => !!v || $t('required.IDNumber')]"
              :placeholder="$t('form.IDNumber')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.phoneNumber") }}</label>
            <v-text-field
              type="number"
              v-model="phone"
              :rules="[(v) => !!v || $t('required.phoneNumber')]"
              :placeholder="$t('form.phoneNumber')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.country") }}</label>
            <v-text-field
              v-model="country"
              :rules="[(v) => !!v || $t('required.country')]"
              :placeholder="$t('form.country')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.password") }}</label>
            <v-text-field
              v-model="password"
              :rules="[
                (v) => !!v || $t('required.password'),
                (v) => v.length >= 10 || $t('required.validPassword')
              ]"
              :placeholder="$t('form.password')"
              autocomplete="off"
              autocorrect="off"
              type="password"
            ></v-text-field>
          </div>
          <div class="uk-width-1-3@m ma-0">
            <label>{{ $t("form.confirmPassword") }}</label>
            <v-text-field
              v-model="passwordConfirm"
              :rules="[
                (v) => !!v || $t('required.field'),
                (v) => v === password || $t('required.notIdentecalPassword')
              ]"
              :placeholder="$t('form.confirmPassword')"
              autocomplete="off"
              autocorrect="off"
              type="password"
            ></v-text-field>
          </div>
          <!--                    <div class="uk-width-1-3@m ma-0">-->
          <!--                        <label>افاتار</label>-->
          <!--                        <v-text-field-->
          <!--                                v-model="avatar"-->
          <!--                                placeholder="الافتار"-->
          <!--                                autocomplete="off"-->
          <!--                                autocorrect="off"-->
          <!--                        ></v-text-field>-->
          <!--                    </div>-->
          <div class="uk-width-1-3@m uk-drop-date ma-0">
            <label class="uk-form-label">{{ $t("form.birthDate") }}</label>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || $t('required.birthDate')]"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="birthDay"
                no-title
                scrollable
                color="#69d1b6"
              >
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn color="#69d1b6" @click="$refs.menu.save(birthDay)" dark>
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div class="uk-width-2-5@m mt-2 text-center">
            <base-button
              @click="signUp()"
              :options="{
                block: true,
                isLoading: loading,
                color: this.primaryColor
              }"
              >{{ $t("form.signup") }}
            </base-button>
            <p>{{ $t("form.bySigning") }}</p>
          </div>
          <div class="uk-width-1-5@m my-5 m-lg-0 text-center">
            - {{ $t("or") }} -
          </div>
          <div class="uk-width-2-5@m ma-0 text-center">
            <base-button :options="{ block: true, color: this.primaryColor }"
              >Google
            </base-button>
            <p>{{ $t("form.signUpThroughGoogle") }}</p>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import BaseButton from "../../../core/Base/Buttons/BaseButton";
import { EventBus } from "../../../main";
import { mapActions } from "vuex";

function notification(group, type, title, duration) {
  return EventBus.$notify({
    group: group,
    type: type,
    title: title,
    duration: duration
  });
}

export default {
  name: "SignUpUser",
  components: { BaseButton },
  data: () => ({
    menu: false,
    date: "",
    valid: true,
    loading: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    password: "",
    passwordConfirm: "",
    identityNumber: "",
    birthDay: "",
    // avatar: "s",
    type: "user"
  }),
  methods: {
    ...mapActions("Auth", ["register"]),
    signUp() {
      if (!this.$refs.form.validate())
        return notification(
          "public",
          "error",
          this.$t("form.checkMissingField"),
          4000
        );
      this.loading = true;
      this.register({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        country: this.country,
        password: this.password,
        identity_number: this.identityNumber,
        birth_date: this.birthDay,
        // avatar: this.avatar,
        type: this.type
      })
        .then(() => {
          notification(
            "public",
            "success",
            this.$t(`resolveResponse.signUpSuccessfuly`),
            4000
          );
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;
          console.log(err.response.data);
          notification(
            "public",
            "error",
            this.$t(`resolveResponse.${errorMessage}`),
            4000
          );
          this.loading = false;
        });
    }
  },
  validations: {
    birthDay: { required }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  color: var(--primary-color);
}
</style>
